import {
  deviceIdToSerial,
  addOnSubscriptionsMock,
  deviceModels,
  tsiLinkSkus,
  genericTSILinkSubscriptionTiers,
  dataServicesSubscriptionTiers,
  subscriptionTiers,
  websiteBasicSubscriptionTiers,
  websitePremiumSubscriptionTiers,
  websiteSubscriptionTiers,
} from './utils'

export default {
  async updateSubscriptions({ commit, getters }, data) {
    const { auth, api } = data

    //not authenticated, no subscriptions
    if (!auth.isAuthenticated) {
      commit('updateFreeTier', [
        {
          name: 'Free Tier',
          totalQtyRemaining: 1,
          totalUsed: 0,
        },
      ])
      commit('updateSubscriptions', [])
      commit('updateGenericTSILink', [])
      commit('updateWebsite', [])
      commit('updateBasic', [])
      commit('updatePremium', [])
      commit('updateWebsiteQtyRemaining', 0, deviceModels.BLUESKY)
      commit('updateWebsiteQtyRemaining', 0, deviceModels.BLUESKY_GAS)
      commit('updateWebsiteQtyRemaining', 0, deviceModels.AIRASSURE)
      commit('updateWebsiteQtyUsed', 0, deviceModels.BLUESKY)
      commit('updateWebsiteQtyUsed', 0, deviceModels.BLUESKY_GAS)
      commit('updateWebsiteQtyUsed', 0, deviceModels.AIRASSURE)
      commit('updateBasicQtyRemaining', 0, deviceModels.BLUESKY)
      commit('updateBasicQtyRemaining', 0, deviceModels.AIRASSURE)
      commit('updatePremiumQtyRemaining', 0, deviceModels.BLUESKY)
      commit('updatePremiumQtyRemaining', 0, deviceModels.BLUESKY_GAS)
      commit('updatePremiumQtyRemaining', 0, deviceModels.AIRASSURE)
      commit('updateDataServices', [])
      return
    }

    // only get skus if not already populated
    if ( !getters.getSKUMetadata ) {
      commit('updateSKUMetadata', await (await api.getSubscriptionSKUMetadata()).json())
    }

    //calculate subscriptions
    const subRes = await api.getUserSubscriptions()
    const subscriptions = await subRes.json()
    const sum = (accum, currentVal) => accum + currentVal
    const calcSubCounts = (sub) => {
      let totalQty, totalQtyRemaining, totalUsed
      const used = []
      if (Array.isArray(sub.qty)) {
        totalQty = sub.qty.reduce(sum)
      } else {
        totalQty = sub.qty
      }
      if (sub.qtyRemaining) {
        if (Array.isArray(sub.qtyRemaining)) {
          totalQtyRemaining = sub.qtyRemaining.reduce(sum)
        } else {
          totalQtyRemaining = sub.qtyRemaining
        }
      } else {
        totalQtyRemaining = 0
      }
      totalUsed = totalQty - totalQtyRemaining
      for (let i = 0; i < sub.qty.length; i++) {
        used[i] = sub.qty[i] - sub.qtyRemaining[i]
      }
      sub.totalQty = totalQty
      sub.totalQtyRemaining = totalQtyRemaining
      sub.totalUsed = totalUsed
      sub.used = used
      return sub
    }

    const mapSubscriptionsToDeviceSerials = (subArray, deviceSerialsMap) => {
      for (const sub of subArray) {
        const flatIds = sub.deviceIds.flat()
        for (const id of flatIds) {
          if (id) {
            deviceSerialsMap[deviceIdToSerial(id)] = sub.id
          }
        }
      }
    }

    const addDefaultStdSubscriptionQtys = (genericSubs) => {
      if (genericSubs.length === 0) return

      const stdSub = genericSubs.find((sub) => {
        return sub.id === subscriptionTiers.TSILINK_STANDARD
      })

      if (stdSub) {
        const mockaddOnSubs = addOnSubscriptionsMock(getters.getSKUMetadataNameById)
        mockaddOnSubs.forEach((mockSub, index) => {
          const addOnSubIndex = genericSubs.findIndex((sub) => {
            return sub.id === mockSub.id
          })

          if (addOnSubIndex > -1) {
            genericSubs[addOnSubIndex].qty[0] += 1
            genericSubs[addOnSubIndex].qtyRemaining[0] += 1
            genericSubs[addOnSubIndex].totalQty += 1
            genericSubs[addOnSubIndex].totalQtyRemaining += 1
          } else {
            mockSub.sapAccountId = stdSub.sapAccountId
            mockSub.activationIds = [`${stdSub.activationIds[0]}${index}`]
            genericSubs.push(mockSub)
          }
        })
      }
    }

    let genericTSILinkSubscriptions = []
    let dataServiceSubscriptions = []
    let websiteSubscriptions = []
    let basicSubscriptions = []
    let premiumSubscriptions = []
    let freeTierSubscriptions = []
    let websiteQtyRemainingBlueSky = 0
    let websiteQtyRemainingBlueSkyGas = 0
    let websiteQtyRemainingAirAssure = 0
    let websiteQtyUsedBlueSky = 0
    let websiteQtyUsedBlueSkyGas = 0
    let websiteQtyUsedAirAssure = 0
    let basicQtyRemainingBlueSky = 0
    let basicQtyRemainingAirAssure = 0
    let premiumQtyRemainingBlueSky = 0
    let premiumQtyRemainingBlueSkyGas = 0
    let premiumQtyRemainingAirAssure = 0

    subscriptions.forEach((sub) => {
      if (sub.name == 'Free Tier') {
        freeTierSubscriptions.push(sub)
      }
      if (
        sub.id.startsWith(tsiLinkSkus.GENERIC) ||
        sub.id.startsWith(deviceModels.BLUESKY) ||
        sub.id.startsWith(deviceModels.BLUESKY_GAS) ||
        sub.id.startsWith(deviceModels.AIRASSURE) ||
        sub.name == 'AirAssureSubscription' ||
        sub.name == 'BlueSkySubscription'
      ) {
        if (genericTSILinkSubscriptionTiers.includes(sub.id)) {
          genericTSILinkSubscriptions.push(sub)
        }
        if (dataServicesSubscriptionTiers.includes(sub.id)) {
          dataServiceSubscriptions.push(sub)
        }
        if (websiteSubscriptionTiers.includes(sub.id)) {
          websiteSubscriptions.push(sub)
        }
        if (websiteBasicSubscriptionTiers.includes(sub.id)) {
          basicSubscriptions.push(sub)
        }
        if (websitePremiumSubscriptionTiers.includes(sub.id)) {
          premiumSubscriptions.push(sub)
        }
      }
    })

    // More complex logic will be required in the future to manage incrementing and decrementing user active subscriptions appropriately
    if(genericTSILinkSubscriptions.length) {
      for (let sub of genericTSILinkSubscriptions) {
        calcSubCounts(sub)
      }
    }

    if (websiteSubscriptions.length > 0) {
      for (let sub of websiteSubscriptions) {
        calcSubCounts(sub)
        if (
          sub.id.startsWith(deviceModels.BLUESKY) ||
          sub.name == 'BlueSkySubscription'
        ) {
          websiteQtyRemainingBlueSky += sub.totalQtyRemaining
          websiteQtyUsedBlueSky += sub.totalUsed
        } else if (
          sub.id.startsWith(deviceModels.BLUESKY_GAS) ||
          sub.name == 'BlueSkyGasSubscription'
        ) {
          websiteQtyRemainingBlueSkyGas += sub.totalQtyRemaining
          websiteQtyUsedBlueSkyGas += sub.totalUsed
        } else {
          websiteQtyRemainingAirAssure += sub.totalQtyRemaining
          websiteQtyUsedAirAssure += sub.totalUsed
        }
      }
    }
    if (basicSubscriptions.length > 0) {
      for (let sub of basicSubscriptions) {
        calcSubCounts(sub)
        if (
          sub.id.startsWith(deviceModels.BLUESKY) ||
          sub.name == 'BlueSkySubscription'
        ) {
          basicQtyRemainingBlueSky += sub.totalQtyRemaining
        } else {
          basicQtyRemainingAirAssure += sub.totalQtyRemaining
        }
      }
    }
    if (premiumSubscriptions.length > 0) {
      for (let sub of premiumSubscriptions) {
        calcSubCounts(sub)
        if (
          sub.id.startsWith(deviceModels.BLUESKY) ||
          sub.name == 'BlueSkySubscription'
        ) {
          premiumQtyRemainingBlueSky += sub.totalQtyRemaining
        } else if (
          sub.id.startsWith(deviceModels.BLUESKY_GAS) ||
          sub.name == 'BlueSkyGasSubscription'
        ) {
          premiumQtyRemainingBlueSkyGas += sub.totalQtyRemaining
        } else {
          premiumQtyRemainingAirAssure += sub.totalQtyRemaining
        }
      }
    }
    if (dataServiceSubscriptions.length > 0) {
      for (let sub of dataServiceSubscriptions) {
        calcSubCounts(sub)
      }
    }
    if (freeTierSubscriptions.length > 0) {
      for (let sub of freeTierSubscriptions) {
        calcSubCounts(sub)
      }
    }
    let devicesSerialFreeTier = {}
    let devicesSerialWebsite = {}
    let devicesSerialBasic = {}
    let devicesSerialPremium = {}
    let devicesSerialDataServices = {}

    // create a map of each device serial to the subscription it is associated with
    mapSubscriptionsToDeviceSerials(websiteSubscriptions, devicesSerialWebsite)
    mapSubscriptionsToDeviceSerials(basicSubscriptions, devicesSerialBasic)
    mapSubscriptionsToDeviceSerials(premiumSubscriptions, devicesSerialPremium)
    mapSubscriptionsToDeviceSerials(dataServiceSubscriptions, devicesSerialDataServices)
    mapSubscriptionsToDeviceSerials(freeTierSubscriptions, devicesSerialFreeTier)

    // Checks if its got a Standard subscription and increments the quantities accordingly
    // TODO: This is only for UI purposes. Will be removed when we implement the backend for this.
    addDefaultStdSubscriptionQtys(genericTSILinkSubscriptions)

    commit('updateSubscriptions', subscriptions)
    commit('updateGenericTSILink', genericTSILinkSubscriptions)
    commit('updateWebsite', websiteSubscriptions)
    commit('updateBasic', basicSubscriptions)
    commit('updatePremium', premiumSubscriptions)
    commit('updateDataServices', dataServiceSubscriptions)
    commit('updateFreeTier', freeTierSubscriptions)

    // update qtyRemaining for each website device type
    commit('updateWebsiteQtyRemaining', {
      qtyRemaining: websiteQtyRemainingBlueSky,
      model: deviceModels.BLUESKY,
    })
    commit('updateWebsiteQtyRemaining', {
      qtyRemaining: websiteQtyRemainingBlueSkyGas,
      model: deviceModels.BLUESKY_GAS,
    })
    commit('updateWebsiteQtyRemaining', {
      qtyRemaining: websiteQtyRemainingAirAssure,
      model: deviceModels.AIRASSURE,
    })
    // update qtyUsed for each website device type
    commit('updateWebsiteQtyUsed', {
      qtyUsed: websiteQtyUsedBlueSky,
      model: deviceModels.BLUESKY,
    })
    commit('updateWebsiteQtyUsed', {
      qtyUsed: websiteQtyUsedBlueSkyGas,
      model: deviceModels.BLUESKY_GAS,
    })
    commit('updateWebsiteQtyUsed', {
      qtyUsed: websiteQtyUsedAirAssure,
      model: deviceModels.AIRASSURE,
    })
    // update qtyRemaining for each basic device type
    commit('updateBasicQtyRemaining', {
      qtyRemaining: basicQtyRemainingBlueSky,
      model: deviceModels.BLUESKY,
    })
    commit('updateBasicQtyRemaining', {
      qtyRemaining: basicQtyRemainingAirAssure,
      model: deviceModels.AIRASSURE,
    })
    // update qtyRemaining for each premium device type
    commit('updatePremiumQtyRemaining', {
      qtyRemaining: premiumQtyRemainingBlueSky,
      model: deviceModels.BLUESKY,
    })
    commit('updatePremiumQtyRemaining', {
      qtyRemaining: premiumQtyRemainingBlueSkyGas,
      model: deviceModels.BLUESKY_GAS,
    })
    commit('updatePremiumQtyRemaining', {
      qtyRemaining: premiumQtyRemainingAirAssure,
      model: deviceModels.AIRASSURE,
    })

    commit('updateDeviceWebsite', devicesSerialWebsite)
    commit('updateDeviceBasic', devicesSerialBasic)
    commit('updateDevicePremium', devicesSerialPremium)
    commit('updateDeviceDataServices', devicesSerialDataServices)
    commit('updateDeviceFreeTier', devicesSerialFreeTier)
  },
}
