<template>
  <div v-if="measurements" class="measurement-list-wrapper">
    <div :class="`measurements ${customClass}`">
      <popover-measurement
        v-for="measurement in measurements"
        :key="measurement.label"
        :reading="measurement.reading"
        :label="measurement.label"
        :unitsLabel="measurement.unitsLabel"
        :imageSrc="measurement.imageSrc"
        :showInactiveReading="measurement.showInactiveReading"
        :showRealValue="true"
        :customClass="measurement.customClass"
      />
    </div>
  </div>
</template>

<script>
import popoverMeasurement from '../popoverMeasurement.vue'

export default {
  name: 'MeasurementList',
  components: {
    'popover-measurement': popoverMeasurement,
  },
  props: {
    measurements: Array,
    customClass: String,
  },
}
</script>

<style lang="scss" scoped>
.measurement-list-wrapper {
  display: flex;
  justify-content: center;

  .measurements {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ::v-deep .measurement {
      width: 100%;
      max-width: 93px;
      margin: 0 4%;
    }
  }
}
</style>
