<template>
  <div :class="{ 'study-container': showStudyContent }">
    <div v-if="!isLoading && !!lastTelemetryTimestamp">
      <div class="study-details" v-if="showStudyContent">
        <popover-timestamp
          :timestamp="minTimestamp"
          :label="$t('widget.currentReadings.studyStart')"
          customClass="small-subtitle"
          dataCy="studyStart"
        />
        <popover-timestamp
          :timestamp="maxTimestamp"
          :label="$t('widget.currentReadings.studyStop')"
          customClass="small-subtitle"
          dataCy="studyStop"
        />
      </div>
      <popover-last-telemetry-timestamp v-else :deviceId="deviceId" />
      <one-measurement-monitoring
        :deviceId="deviceId"
        :selectedParameter="selectedParameter"
        :measurementReadings="readings"
        :size="showStudyContent ? 'medium' : 'large'"
      />
      <measurement-statistics
        v-if="showStudyContent"
        :deviceId="deviceId"
        :measurementStatisticsReadings="measurementStatisticsReadings"
        :selectedParameter="selectedParameter"
      />
      <device-conditions :deviceConditionsArray="deviceConditionsErrors" />
    </div>
    <no-data-container v-else-if="!isLoading" />
    <loading-container v-else />
  </div>
</template>
<script>
import LoadingContainer from '@/components/devicePopover/layouts/LoadingContainer.vue'
import MeasurementStatistics from '@/components/widgets/currentReadings/content/MeasurementStatistics.vue'
import NoDataContainer from '@/components/devicePopover/layouts/NoDataContainer.vue'
import OneMeasurementMonitoring from '@/components/devicePopover/layouts/OneMeasurementMonitoring.vue'
import popoverLastTelemetryTimestamp from '@/components/devicePopover/content/header/popoverLastTelemetryTimestamp.vue'
import popoverTimestamp from '@/components/devicePopover/content/popoverTimestamp.vue'
import DeviceConditions from '../../widgets/currentReadings/content/DeviceConditions.vue'
import { getStatisticsReadingsFromStudyStates } from '@/helpers/studies/statisticsHelper'
import { getTelemetryReadingsObject } from '@/helpers/devices/telemetryHelper.js'
import { getDeviceConditionsErrorsFromStudyStates } from '@/helpers/studies/deviceConditionsHelper.js'

export default {
  name: 'ContinuousMonitoringAndStudyContent',
  components: {
    'loading-container': LoadingContainer,
    'measurement-statistics': MeasurementStatistics,
    'no-data-container': NoDataContainer,
    'one-measurement-monitoring': OneMeasurementMonitoring,
    'popover-timestamp': popoverTimestamp,
    'popover-last-telemetry-timestamp': popoverLastTelemetryTimestamp,
    'device-conditions': DeviceConditions,
  },
  props: {
    deviceId: String,
    isLoading: Boolean,
    latestDeviceStudy: Object,
    selectedParameter: Number,
    showStudyContent: Boolean,
  },
  computed: {
    lastTelemetryTimestamp() {
      return this.$store.getters['devices/getLastContactByDeviceId'](
        this.deviceId
      )
    },
    latestStudyTelemetry() {
      if (this.latestDeviceStudy?.telemetry?.length) {
        const latestStudyTelemetry = this.latestDeviceStudy.telemetry
        return latestStudyTelemetry[latestStudyTelemetry.length - 1]
      }
      return null
    },
    readings() {
      // If there is a study_id in telemetry, but the latest study is null
      if (this.showStudyContent && !this.latestDeviceStudy) {
        return {}
      }

      return this.latestStudyReadings
    },
    latestStudyReadings() {
      return getTelemetryReadingsObject(this.latestStudyTelemetry)
    },
    measurementStatisticsReadings() {
      // If there is a study_id in telemetry, but the latest study is null
      if (this.showStudyContent && !this.latestDeviceStudy) {
        return {}
      }

      return getStatisticsReadingsFromStudyStates(
        this.latestDeviceStudy?.states,
        this.latestStudyTelemetry?.timestamp
      )
    },
    deviceConditionsErrors() {
      return getDeviceConditionsErrorsFromStudyStates(
        this.latestDeviceStudy?.states
      )
    },
    minTimestamp() {
      return this.latestDeviceStudy?.min_timestamp || ''
    },
    maxTimestamp() {
      return this.latestDeviceStudy?.max_timestamp || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.study-details {
  display: flex;
  justify-content: space-between;
}

::v-deep .device-conditions-wrapper {
  margin-top: 25px;
}
</style>
