//Convert model-serial into serial without model number and hyphen
export const deviceIdToSerial = function(deviceId) {
  let identifier,serial;
  identifier = deviceId.split('-');
  serial = identifier.length ? identifier[identifier.length - 1] : '';
  return serial;
}

//TODO: remove when the rest of the TODOs are done as it will not be necessary
export const deviceModels = Object.freeze({
  BLUESKY: '8143',
  AIRASSURE: '8144',
  BLUESKY_GAS: '8145',
  SMART_BRIDGE: 'sbd1',
  DUST_TRACK_8530: '8530',
  DUST_TRACK_8530EP: '8530EP',
  DUST_TRACK_8531: '8531',
  DUST_TRACK_8533: '8533',
  DUST_TRACK_8533EP: '8533EP',
  DUST_TRACK_8532: '8532',
  DUST_TRACK_8534: '8534',
  DUST_TRACK_8540: '8540',
  DUST_TRACK_8540M: '8540-M',
  DUST_TRACK_8542M: '8542-M',
  DUST_TRACK_8543: '8543',
  DUST_TRACK_8543M: '8543-M',
})

//TODO: remove when the rest of the TODOs are done as it will not be necessary
export const tsiLinkSkus = Object.freeze({
  GENERIC: '7593'
})

//TODO: Obtain this from the getSubscriptions API call
export const subscriptionTypes = Object.freeze({
  DUAL: 'dual',
  DATA_SERVICES: 'dataservices',
  WEBSITE_PREMIUM: 'premium',
  WEBSITE_BASIC: 'basic',
  WEBSITE: 'website',
  DATA_AND_FREE: 'dataAndFree',
  FREE_TIER: 'free',
  DEVICE_SMART_BRIDGE: 'SBD2',
  STUDIES: 'studies',
  FREE_TIER_API_RESPONSE: 'freetier',
  NOT_APPLICABLE: 'notAvailable',
})

export const subscriptionGeneralTiers = Object.freeze({
  BASIC: 'basic',
  PREMIUM: 'premium',
})

//TODO: Obtain this from the getSubscriptions API call
//which in turn an be obtained through the call that gets
//subscription roles from the cache or mongo if not and
//caches them
export const subscriptionTiers = Object.freeze({
  TSILINK_ENTRY: `${tsiLinkSkus.GENERIC}-16`,
  TSILINK_STANDARD: `${tsiLinkSkus.GENERIC}-19`,
  TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE: `${tsiLinkSkus.GENERIC}-29`,
  TSILINK_ADD_ON_DATA_STORAGE: `${tsiLinkSkus.GENERIC}-30`,
  TSILINK_ADD_ON_FILE_IMPORT: `${tsiLinkSkus.GENERIC}-31`,
  BLUESKY_PREMIUM_MONTHLY: `${deviceModels.BLUESKY}-M`,
  BLUESKY_PREMIUM_ANNUAL: `${deviceModels.BLUESKY}-A`,
  BLUESKY_PREMIUM_MULTIYEAR: `${deviceModels.BLUESKY}-PS1`,
  BLUESKY_GAS_PREMIUM_MONTHLY: `${deviceModels.BLUESKY_GAS}-M`,
  BLUESKY_GAS_PREMIUM_ANNUAL: `${deviceModels.BLUESKY_GAS}-A`,
  BLUESKY_GAS_PREMIUM_MULTIYEAR: `${deviceModels.BLUESKY_GAS}-PS1`,
  AIRASSURE_BASIC_MONTHLY: `${deviceModels.AIRASSURE}-MB`,
  AIRASSURE_BASIC_ANNUAL: `${deviceModels.AIRASSURE}-AB`,
  AIRASSURE_PREMIUM_MONTHLY: `${deviceModels.AIRASSURE}-M`,
  AIRASSURE_PREMIUM_ANNUAL: `${deviceModels.AIRASSURE}-A`,
  AIRASSURE_PREMIUM_MULTIYEAR: `${deviceModels.AIRASSURE}-PS1`,
  BLUESKY_DATA_SERVICES_MONTHLY: `${deviceModels.BLUESKY}-DSM`,
  BLUESKY_DATA_SERVICES_ANNUAL: `${deviceModels.BLUESKY}-DSA`,
  BLUESKY_DATA_SERVICES_MULTIYEAR: `${deviceModels.BLUESKY}-DS1`,
  BLUESKY_GAS_DATA_SERVICES_MONTHLY: `${deviceModels.BLUESKY_GAS}-DSM`,
  BLUESKY_GAS_DATA_SERVICES_ANNUAL: `${deviceModels.BLUESKY_GAS}-DSA`,
  BLUESKY_GAS_DATA_SERVICES_MULTIYEAR: `${deviceModels.BLUESKY_GAS}-DS1`,
  AIRASSURE_DATA_SERVICES_MONTHLY: `${deviceModels.AIRASSURE}-DSM`,
  AIRASSURE_DATA_SERVICES_ANNUAL: `${deviceModels.AIRASSURE}-DSA`,
  AIRASSURE_DATA_SERVICES_MULTIYEAR: `${deviceModels.AIRASSURE}-DS1`,
  FREE_TIER: 'Free Tier'
})

//TODO: Calculate this by passing in subscription id
//use subscriptions that have M -> Monthly
export const monthlySubscriptions = [
  subscriptionTiers.BLUESKY_PREMIUM_MONTHLY,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_MONTHLY,
  subscriptionTiers.AIRASSURE_BASIC_MONTHLY,
  subscriptionTiers.AIRASSURE_PREMIUM_MONTHLY,
  subscriptionTiers.BLUESKY_DATA_SERVICES_MONTHLY,
  subscriptionTiers.BLUESKY_GAS_DATA_SERVICES_MONTHLY,
  subscriptionTiers.AIRASSURE_DATA_SERVICES_MONTHLY,
]

//TODO: Calculate this by passing in subscription id
//use subscriptions that have website
export const websiteSubscriptionTiers = [
  subscriptionTiers.BLUESKY_PREMIUM_MONTHLY,
  subscriptionTiers.BLUESKY_PREMIUM_ANNUAL,
  subscriptionTiers.BLUESKY_PREMIUM_MULTIYEAR,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_MONTHLY,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_ANNUAL,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_MULTIYEAR,
  subscriptionTiers.AIRASSURE_BASIC_MONTHLY,
  subscriptionTiers.AIRASSURE_BASIC_ANNUAL,
  subscriptionTiers.AIRASSURE_PREMIUM_MONTHLY,
  subscriptionTiers.AIRASSURE_PREMIUM_ANNUAL,
  subscriptionTiers.AIRASSURE_PREMIUM_MULTIYEAR,
]

//TODO: Calculate this by passing in subscription id
//use subscriptions that have website and M -> Monthly
export const websiteBasicSubscriptionTiers = [
  subscriptionTiers.AIRASSURE_BASIC_MONTHLY,
  subscriptionTiers.AIRASSURE_BASIC_ANNUAL,
]

//TODO: Calculate this by passing in subscription id
//use subscriptions that have website and P -> Premium
export const websitePremiumSubscriptionTiers = [
  subscriptionTiers.BLUESKY_PREMIUM_MONTHLY,
  subscriptionTiers.BLUESKY_PREMIUM_ANNUAL,
  subscriptionTiers.BLUESKY_PREMIUM_MULTIYEAR,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_MONTHLY,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_ANNUAL,
  subscriptionTiers.BLUESKY_GAS_PREMIUM_MULTIYEAR,
  subscriptionTiers.AIRASSURE_PREMIUM_MONTHLY,
  subscriptionTiers.AIRASSURE_PREMIUM_ANNUAL,
  subscriptionTiers.AIRASSURE_PREMIUM_MULTIYEAR,
]

//TODO: Calculate this by passing in subscription id
//use subscriptions that have dataservices
export const dataServicesSubscriptionTiers = [
  subscriptionTiers.BLUESKY_DATA_SERVICES_MONTHLY,
  subscriptionTiers.BLUESKY_DATA_SERVICES_ANNUAL,
  subscriptionTiers.BLUESKY_DATA_SERVICES_MULTIYEAR,
  subscriptionTiers.BLUESKY_GAS_DATA_SERVICES_MONTHLY,
  subscriptionTiers.BLUESKY_GAS_DATA_SERVICES_ANNUAL,
  subscriptionTiers.BLUESKY_GAS_DATA_SERVICES_MULTIYEAR,
  subscriptionTiers.AIRASSURE_DATA_SERVICES_MONTHLY,
  subscriptionTiers.AIRASSURE_DATA_SERVICES_ANNUAL,
  subscriptionTiers.AIRASSURE_DATA_SERVICES_MULTIYEAR,
]

//TODO: Calculate this by passing in subscription id
//use subscriptions that have generic tsi link subscriptions
export const genericTSILinkSubscriptionTiers = [
  subscriptionTiers.TSILINK_ENTRY,
  subscriptionTiers.TSILINK_STANDARD,
  subscriptionTiers.TSILINK_ADD_ON_DATA_STORAGE,
  subscriptionTiers.TSILINK_ADD_ON_FILE_IMPORT,
  subscriptionTiers.TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE
]

export const paymentTypes = Object.freeze({
  PO: 'PO',
  CC: 'CC',
})

// TODO: This should be translated
export const renewalDateStatuses = Object.freeze({
  Canceled: 'Canceled',
  CancellationPending: 'Cancellation Pending',
  AutoRenewal: 'Auto Renewal',
  NotApplicable: 'Not Applicable'
})

export const addOnSubscriptionsMock = (subIdToSkuNameFn) => [
  {
    id: '7593-31',
    sapAccountId: 0,
    name: subIdToSkuNameFn('7593-31'),
    activationIds: [],
    qty: [1],
    qtyRemaining: [1],
    expires: [null],
    activationDate: [],
    deviceIds: [null],
    canceled: [null],
    billingInfo: [
      {
        autoRenewalDate: null,
        ccNumLastFour: null,
        purchaseOrder: null,
        paymentType: null
      }
    ],
    totalQty: 1,
    totalQtyRemaining: 1,
    totalUsed: 0,
    used: [0]
  },
  {
    id: '7593-30',
    sapAccountId: 0,
    name: subIdToSkuNameFn('7593-30'),
    activationIds: [],
    qty: [1],
    qtyRemaining: [1],
    expires: [null],
    activationDate: [],
    deviceIds: [null],
    canceled: [null],
    billingInfo: [
      {
        autoRenewalDate: null,
        ccNumLastFour: null,
        purchaseOrder: null,
        paymentType: null
      }
    ],
    totalQty: 1,
    totalQtyRemaining: 1,
    totalUsed: 0,
    used: [0]
  }
]
