var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-content"},[_c('v-card-actions',{staticClass:"ma-0 pa-0"},[(_vm.fullscreen && !_vm.$vuetify.breakpoint.xs)?_c('v-card-subtitle',{attrs:{"data-cy":"historyGraphLabel"}},[_vm._v(_vm._s(_vm.graphLabel))]):_vm._e(),_c('v-spacer'),_c('div',{ref:"timePeriodControl",staticClass:"timePeriodControl"},[_c('span',{staticClass:"timePeriodControl__label"},[_vm._v(" "+_vm._s(_vm.$t('map.historyDialog.timePeriodLabel'))+" ")]),_c('div',{staticClass:"timePeriodControl__controls"},[(_vm.allowDay)?_c('v-btn',{attrs:{"color":_vm.getButtonColor('day'),"depressed":"","small":"","data-cy":"historyTimePeriodDay","disabled":_vm.isLoading},on:{"click":function($event){return _vm.changeTimePeriod('day')}}},[_vm._v(_vm._s(_vm.$t('map.historyDialog.timePeriod.day')))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasWeeklyPermission},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.allowWeek)?_c('v-btn',_vm._g(_vm._b({class:[{ restrictedContent: !_vm.hasWeeklyPermission }],attrs:{"color":_vm.getButtonColor('week'),"depressed":"","small":"","data-cy":"historyTimePeriodWeek","disabled":_vm.isLoading},on:{"click":function($event){return _vm.changeTimePeriod('week')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('map.historyDialog.timePeriod.week')))]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.getPermissionTooltip()))])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasMonthlyPermission},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.allowMonth)?_c('v-btn',_vm._g(_vm._b({class:[{ restrictedContent: !_vm.hasMonthlyPermission }],attrs:{"color":_vm.getButtonColor('month'),"depressed":"","small":"","data-cy":"historyTimePeriodMonth","disabled":_vm.isLoading},on:{"click":function($event){return _vm.changeTimePeriod('month')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('map.historyDialog.timePeriod.month')))]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.getPermissionTooltip()))])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasThreeMonthsPermission},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.allowThreeMonths)?_c('v-btn',_vm._g(_vm._b({class:[{ restrictedContent: !_vm.hasThreeMonthsPermission }],attrs:{"color":_vm.getButtonColor('threeMonths'),"depressed":"","small":"","data-cy":"historyTimePeriodThreeMonths","disabled":_vm.isLoading},on:{"click":function($event){return _vm.changeTimePeriod('threeMonths')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('map.historyDialog.timePeriod.threeMonths')))]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.getPermissionTooltip()))])])],1)])],1),(!_vm.fullscreen || _vm.$vuetify.breakpoint.xs)?_c('v-card-subtitle',{ref:"historyGraphLabel",attrs:{"data-cy":"historyGraphLabel"}},[_vm._v(_vm._s(_vm.graphLabel)+" ")]):_vm._e(),_c('div',{ref:"graph",staticClass:"graph",class:{ 'opacity-20': _vm.isLoading },style:(("height: " + _vm.graphHeight + "; width: " + _vm.graphWidth + ";")),attrs:{"data-cy":"historyGraph"}}),(_vm.isLoading || _vm.redrawGraph)?_c('div',{staticClass:"loading-container",class:{
      'loading-container-fullscreen': _vm.fullscreen,
    }},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.numShownDatasets < 1 && !_vm.isLoading && _vm.graphDrawn)?_c('div',{staticClass:"empty-message",class:{
      'empty-message-fullscreen': _vm.fullscreen && !_vm.$vuetify.breakpoint.xs,
    },attrs:{"data-cy":"historyGraphEmptyMessage"}},[_vm._v(" "+_vm._s(_vm.$t('map.historyDialog.graph.emptyMessage'))+" ")]):_vm._e(),(_vm.selectedDevices.length > 0)?_c('v-row',{ref:"selectedDevices",staticClass:"selected-devices",class:{ 'opacity-20': _vm.isLoading }},_vm._l((_vm.selectedDevices),function(device,index){return _c('div',{key:device.id,class:{ inactive: !_vm.isDeviceActive(device) },style:(("border-left: 4px solid " + (_vm.graphColors[index]) + ";")),attrs:{"data-cy":("historyDialogDeviceName" + index)}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","disabled":_vm.disableDeviceTooltip(device)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"white","label":"","small":""}},on),[(_vm.isDeviceIndoor(device.id))?_c('img',{staticClass:"selected-device-icon",attrs:{"src":_vm.indoorIconSrc(device.model),"alt":"Indoor Device Icon"}}):_c('img',{staticClass:"selected-device-icon",attrs:{"src":_vm.outdoorIconSrc(device.model),"alt":"Outdoor Device Icon"}}),(device.friendlyName)?_c('span',{ref:device.id,refInFor:true,staticClass:"device-name"},[_c('span',[_vm._v(_vm._s(device.friendlyName))])]):_c('span',{staticClass:"device-name"},[_vm._v(" "+_vm._s(_vm.$t('map.popover.publicDevice'))+" "+_vm._s(index + 1)+" ")]),(_vm.removableDevices)?_c('v-icon',{attrs:{"right":""},on:{"click":function($event){return _vm.removeDevice(index)}}},[_vm._v(" mdi-close ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDeviceTooltip(device)))])])],1)}),0):_vm._e(),(_vm.selectedDevices.length === 5)?_c('div',{ref:"maximumMetMessage",staticClass:"maximum-met-message"},[_vm._v(" "+_vm._s(_vm.$t('map.historyDialog.maximumMet'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }