export default {
  SKUMetadata: null,
  subscriptions: [],
  freeTier: [],
  genericTSILink: [],
  website: [],
  basic: [],
  premium: [],
  websiteQtyRemainingBlueSky: 0,
  websiteQtyUsedBlueSky: 0,
  websiteQtyRemainingBlueSkyGas: 0,
  websiteQtyUsedBlueSkyGas: 0,
  websiteQtyRemainingAirAssure: 0,
  websiteQtyUsedAirAssure: 0,
  basicQtyRemainingBlueSky: 0,
  basicQtyRemainingAirAssure: 0,
  premiumQtyRemainingBlueSky: 0,
  premiumQtyRemainingBlueSkyGas: 0,
  premiumQtyRemainingAirAssure: 0,
  dataServices: [],
  deviceFreeTier: null,
  deviceWebsite: null,
  deviceBasic: null,
  devicePremium: null,
  deviceDataServices: null,
  permissionsInRole: {},
}
