<template>
  <div class="device-condition" :data-cy="dataCy">
    <img
      v-if="showNotAvailableIcon"
      src="/img/measurements/not_available.svg"
      alt="Not available device condition"
    />
    <v-icon v-else small :color="color"> {{ icon }} </v-icon>
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'DeviceConditions',
  props: {
    label: String,
    dataCy: String,
    showDeviceConditionError: Boolean,
    showNotAvailableIcon: Boolean,
  },
  computed: {
    color() {
      return this.showDeviceConditionError ? 'red' : 'green'
    },
    icon() {
      return this.showDeviceConditionError
        ? 'mdi-alert-circle'
        : 'mdi-check-circle'
    },
  },
}
</script>

<style lang="scss" scoped>
.device-condition {
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 3%;
  width: 65px;

  .v-icon {
    font-size: 1.2rem !important;
  }

  img {
    width: 16px;
  }
}
</style>
