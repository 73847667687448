import { deviceModels } from './utils'

export default {
  updateSKUMetadata(state, SKUMetadata) {
    state.SKUMetadata = SKUMetadata
  },
  updateSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions
  },
  updateFreeTier(state, subscriptions) {
    state.freeTier = subscriptions
  },
  updateGenericTSILink(state, subscriptions) {
    state.genericTSILink = subscriptions
  },
  updateWebsite(state, subscriptions) {
    state.website = subscriptions
  },
  updateBasic(state, subscriptions) {
    state.basic = subscriptions
  },
  updatePremium(state, subscriptions) {
    state.premium = subscriptions
  },
  updateDeviceFreeTier(state, devices) {
    state.deviceFreeTier = devices
  },
  updateDeviceWebsite(state, devices) {
    state.deviceWebsite = devices
  },
  updateDeviceBasic(state, devices) {
    state.deviceBasic = devices
  },
  updateDevicePremium(state, devices) {
    state.devicePremium = devices
  },
  updateDeviceDataServices(state, devices) {
    state.deviceDataServices = devices
  },
  updateWebsiteQtyUsed(state, { qtyUsed, model }) {
    if (model === deviceModels.BLUESKY) {
      state.websiteQtyUsedBlueSky = qtyUsed
    } else if (model === deviceModels.BLUESKY_GAS) {
      state.websiteQtyUsedBlueSkyGas = qtyUsed
    } else if (model === deviceModels.AIRASSURE) {
      state.websiteQtyUsedAirAssure = qtyUsed
    }
    //do nothing if model unknown
  },
  updateWebsiteQtyRemaining(state, { qtyRemaining, model }) {
    if (model === deviceModels.BLUESKY) {
      state.websiteQtyRemainingBlueSky = qtyRemaining
    } else if (model === deviceModels.BLUESKY_GAS) {
      state.websiteQtyRemainingBlueSkyGas = qtyRemaining
    } else if (model === deviceModels.AIRASSURE) {
      state.websiteQtyRemainingAirAssure = qtyRemaining
    }
    //do nothing if model unknown
  },
  updateBasicQtyRemaining(state, { qtyRemaining, model }) {
    if (model === deviceModels.BLUESKY) {
      state.basicQtyRemainingBlueSky = qtyRemaining
      return
    } else if (model === deviceModels.AIRASSURE) {
      state.basicQtyRemainingAirAssure = qtyRemaining
      return
    }
    //do nothing if model unknown
  },
  updatePremiumQtyRemaining(state, { qtyRemaining, model }) {
    if (model === deviceModels.BLUESKY) {
      state.premiumQtyRemainingBlueSky = qtyRemaining
      return
    } else if (model === deviceModels.BLUESKY_GAS) {
      state.premiumQtyRemainingBlueSkyGas = qtyRemaining
      return
    } else if (model === deviceModels.AIRASSURE) {
      state.premiumQtyRemainingAirAssure = qtyRemaining
      return
    }
    //do nothing if model unknown
  },
  updateDataServices(state, subscriptions) {
    state.dataServices = subscriptions
  },
}
