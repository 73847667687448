export const getDeviceConditionsErrorsFromStudyStates = (states) => {
  if (states?.length) {
    for (const state of states) {
      if (state?.device_state_data?.error_state?.value) {
        return state.device_state_data.error_state.value
      }
    }
  }

  return null
}
